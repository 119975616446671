@use "../../../../../assets/theme/main";

.FloatBox {
  width: 100%;
  height: 100%;
  z-index: 5;
  position: absolute;
  //background-color: red;
  display: flex;
  //opacity: 0;
  align-items: center;
  padding: 0 15%;
}

.wrapper {
  background-color: main.$white;
  border-radius: main.$border-radius3;
  padding: 2em;
  width: 50%;
  text-align: center;
  * {
    margin: 1em 0;
  }
}