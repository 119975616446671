$font: 'Roboto', sans-serif;

h1, h2, h3, h4, h5, h6, .body1, .body2, .caption-text, .overline-text, .subtitle1, .subtitle2, .button-text {
  margin: 0;
}

h1 {
  font-family: $font;
  font-size: 6rem; /* 96px */
  font-weight: 300;
  line-height: 1.167; /* Adjusted for MUI consistency */
}

h2 {
  font-family: $font;
  font-size: 3.75rem; /* 60px */
  font-weight: 300;
  line-height: 1.2; /* Adjusted for MUI consistency */
}

h3 {
  font-family: $font;
  font-size: 3rem; /* 48px */
  font-weight: 400;
  line-height: 1.167; /* Adjusted for MUI consistency */
}

h4 {
  font-family: $font;
  font-size: 2.125rem; /* 34px */
  font-weight: 400;
  line-height: 1.235; /* Adjusted for MUI consistency */
}

h5 {
  font-family: $font;
  font-size: 1.5rem; /* 24px */
  font-weight: 400;
  line-height: 1.334; /* Adjusted for MUI consistency */
}

h6 {
  font-family: $font;
  font-size: 1.25rem; /* 20px */
  font-weight: 500;
  line-height: 1.6; /* Adjusted for MUI consistency */
}

a {
  font-family: $font;
}

p, .body1, div {
  font-family: $font;
  font-size: 1rem; /* 16px */
  font-weight: 400;
  line-height: 1.5; /* Standard for body text, aligns with MUI */
}

.body2 {
  font-family: $font;
  font-size: 0.875rem; /* 14px */
  font-weight: 400;
  line-height: 1.43; /* Adjusted for MUI consistency */
}

.subtitle1 {
  font-family: $font;
  font-size: 1rem; /* 16px */
  font-weight: 400;
  line-height: 1.75; /* Adjusted for MUI consistency */
}

.subtitle2 {
  font-family: $font;
  font-size: 0.875rem; /* 14px */
  font-weight: 500;
  line-height: 1.57; /* Adjusted for MUI consistency */
}

.button-text {
  font-family: $font;
  font-size: 0.875rem; /* 14px */
  font-weight: 500;
  text-transform: uppercase;
  line-height: 2.5; /* Adjusted, high for button text to ensure vertical centering */
}

.caption-text {
  font-family: $font;
  font-size: 0.75rem; /* 12px */
  font-weight: 400;
  line-height: 1.66; /* Adjusted for MUI consistency */
}

.overline-text {
  font-family: $font;
  font-size: 0.625rem; /* 10px */
  font-weight: 500;
  text-transform: uppercase;
  line-height: 2; /* Adjusted, high for overline to ensure visibility and spacing */
}
