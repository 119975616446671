
$border-radius1: .2em;
$border-radius2: .5em;
$border-radius3: 1em;
$border-radius4: 3em;
$border-radius5: 5em;
$border-radius-Circle: 100%;

$transition-easy1: .5s cubic-bezier(.3, .6, .13, .92);
$transition-easy2: .8s cubic-bezier(.3, .6, .13, .92);
$transition-easy3: .2s cubic-bezier(.3, .6, .13, .92);


$box-shadow1: 0 8px 0.5px -3px #00000080;
$box-shadow2: 0 8px 2px -3px #00000020;
$box-shadow3: -15px 20px 4px -5px #00000080;


$duration-short: .5;
$duration-medium: 1;
$duration-long: 1.5;
$duration-longer: 2;
$cubic: cubic-bezier(.3, .6, .13, .92);

// Color Palette
$background: rgb(250, 243, 221);
$background2: rgb(222, 255, 241);
$white: rgb(255, 255, 255);
$black: rgb(0, 0, 0);
$grey: rgb(39, 39, 39);
$primary: rgb(20, 43, 52);
$secondary: rgb(118, 118, 65);
$tertiary: rgb(151, 193, 216);
$color1: rgb(47, 94, 115);
$color2: rgb(53, 184, 149);
$color3: rgb(222, 255, 241);
$transparent: rgba(0,0,0,0);


//Border Radius
$modal-radius: 5em;
$border-radius: 1em;

//Box Shadow
$modal-shadow: -18px 15px 0 0 #00000075;
$shadow-light: 0 8px 2px -3px #00000020;

$font-sizeMedium: 2em;

$font-cursive: 'Youthline', sans-serif;
$font-regular: 'Roboto', sans-serif;


:export {
  primary: $primary;
  secondary: $secondary;
  tertiary: $tertiary;
  white: $white;
  black: $black;
  grey: $grey;
  background: $background;
  background2: $background2;
  color1: $color1;
  color2: $color2;
  color3: $color3;
  transparent: $transparent;
}


