// Define Mixins for Media Queries

// Small devices (portrait phones, less than 576px)
@mixin small {
  @media (max-width: 575.98px) {
    @content;
  }
}

// Medium devices (landscape phones, less than 768px)
@mixin medium {
  @media (max-width: 767.98px) {
    @content;
  }
}

// Large devices (tablets, less than 992px)
@mixin large {
  @media (max-width: 991.98px) {
    @content;
  }
}

// Larger devices (desktops, less than 1200px)
@mixin larger {
  @media (max-width: 1199.98px) {
    @content;
  }
}
