.heroSection {
  height: 100vh; /* Full viewport height */
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.heroImage {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Makes sure the image covers the entire section */
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1; /* Ensures image is behind any other content */
}
