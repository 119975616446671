@use '../src/assets/theme/main';

/* CSS Reset for margin and padding */
html, body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Apply box-sizing globally to all elements */
*,
*::before,
*::after {
  box-sizing: border-box;
}

$marginTopBottom: 6em 0;

.cursive {
  font-family: "Pacifico", cursive;
}

img {
  object-fit: cover;
}

.center {
  text-align: center;
}

.rows {
  display: flex;
  gap: 4em;
  justify-content: space-between;
  margin: $marginTopBottom;
  flex-direction: row;
}

.margin {
  margin: 0 7em;
}

.section {
  padding: 5em 0;
  p {
    color: main.$black;
  }
}

.section1 {
  background-color: main.$background;
  color: main.$primary;
}

.section2 {
  background-color: main.$white;
  color: main.$primary;
}

.section3 {
  background-color: main.$background;
  color: main.$primary;
  .rows {
    justify-content: center;
  }

  .square {
    background-color: main.$white;
    width: 30em;
    height: 30em;
    border-radius: main.$border-radius2;
    overflow: hidden;

    img {
      height: 100%;
      width: auto;
    }

  }

  .squarePad {
    padding: 3em;

    * {
      margin: 2em 0;
    }
  }
}

.section4 {
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;

  .box {
    position: absolute;
    z-index: 1;
    width: 50%;
    background-color: main.$white;
    padding: 2em;
    border-radius: main.$border-radius2;
  }

  .parallaxWrapper {
    height: 30em;
    display: flex; /* Centering the image */
    justify-content: center; /* Horizontally center */
    align-items: center; /* Vertically center */
    overflow: hidden; /* Crop any overflow */

    img {
      width: 100%; /* Image takes full width */
      height: auto; /* Image takes full height */

    }
  }
}


.rowImages {
  height: 30em;
  width: 100%;
  padding: 0 3em;
  display: flex;
  margin: $marginTopBottom;
  justify-content: space-between;
  img {
    width: auto;
    height: 100%;
    border-radius: main.$border-radius2;
  }
}

