@use '../../../../assets/theme/main';

.navbar {
  color: main.$white;
  padding: 10px 20px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
  margin: 0;
  transition: background-color 0.5s ease, box-shadow 0.5s ease;
}

.navbarContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navbarTitle {
  font-size: 24px;
  color: white;
  text-decoration: none;
}

.navbarLinks {
  display: flex;
  gap: 20px;
}

.navbarItem {
  color: white;
  text-decoration: none;
  font-size: 18px;
}

.navbarItem:hover {
  text-decoration: underline;
}

.navbarToggle {
  display: none;
  flex-direction: column;
  cursor: pointer;
}

.bar {
  height: 3px;
  width: 25px;
  background-color: white;
  margin: 4px 0;
}

/* Sliding panel for smaller screens */
.navbarPanel {
  position: fixed;
  top: 0;
  right: -250px; /* Start off-screen */
  width: 250px;
  height: 100%;
  background-color: #333;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 20px;
  transition: right 0.3s ease;
  z-index: 15;
}

.navbarPanel.open {
  right: 0; /* Slide in */
}

.navbarPanelContent {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.backdrop {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease;
  z-index: 5;
}

.backdropVisible {
  opacity: 1;
  visibility: visible;
}

/* Media Queries */
@include main.small {
  .navbarLinks {
    display: none;
  }

  .navbarToggle {
    display: flex;
  }
}

@include main.medium {
  .navbarLinks {
    display: none;
  }

  .navbarToggle {
    display: flex;
  }
}

@media (min-width: 769px) {
  .navbarPanel,
  .backdrop {
    display: none;
  }
}
