// MaintenancePage.module.scss
@import "../../../assets/theme/main";
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 2em;
  height: 100vh;
  background-color: $background;
  text-align: center;
}

.animation {
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
}

.gear {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: $primary;
  position: relative;
  margin: 0 10px;
  animation: spin 2s linear infinite;

  &:before,
  &:after {
    content: '';
    position: absolute;
    background-color: $primary;
    border-radius: 50%;
  }

  &:before {
    width: 20px;
    height: 20px;
    top: 5px;
    left: 5px;
  }

  &:after {
    width: 10px;
    height: 10px;
    bottom: 5px;
    right: 5px;
  }
}

.message {
  font-size: 2rem;
  color: #2c3e50;
  margin-bottom: 1rem;
}

.subMessage {
  font-size: 1.25rem;
  color: #7f8c8d;
}
