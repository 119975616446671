.parallaxWrapper {
  position: relative;
  height: 100vh; /* Adjust based on your layout needs */
  overflow: hidden;
}

.parallaxLayer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; /* Ensure all layers take full screen height */
  display: flex;
  justify-content: center;
  align-items: center;
  will-change: transform; /* Optimize for scrolling */
}
